@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body
{
    background-color: #F3F1EA;
}

/* COLOURS */

/* background */
/* #F3F1EA */

/* main colour */
/* #668F8F */

/* brown accent */
/* #8D5B4C */